<template>
  <v-row>
    <v-col cols="12" class="px-8">
      <div class="font-weight-bold main--text text-center number-label">
        <number-display
          :from="0"
          :to="success"
          :duration="1"
          :format="formatToPercent"
          easing="Power1.easeOut"
          class="font-italic"
        />

        <div class="font-weight-bold text-heading-6 mt-6">
          {{ $t("sales_achievement_rate") }}
        </div>
      </div>
      <ChartAchievementRate :threshold="total" :value="progress" />
    </v-col>
  </v-row>
</template>

<script>
import ChartAchievementRate from "@/components/charts/ChartRadialAchievementRate";
import NumberDisplay from "@/components/ui/Number";
export default {
  name: "AchievementRateChart",
  components: { NumberDisplay, ChartAchievementRate },
  props: {
    total: {
      type: Number,
      default: 0,
      required: false
    },
    progress: {
      type: Number,
      default: 0,
      required: false
    },
    success: {
      type: Number,
      default: 0,
      required: false
    }
  },
  data() {
    return {};
  },
  methods: {
    formatToPercent(value) {
      if (value === 100) {
        return value.toFixed(1);
      }
      let decimal = value.toFixed(1).split(".")[1];
      return `${Math.floor(
        value
      )}<span class="decimal-point-label">.${decimal}%</span>`;
    }
  }
};
</script>

<style scoped>
.number-label {
  width: 100%;
  font-size: 70px;
  position: relative;
  top: 40%;
  margin-bottom: -70px;
}
</style>
