<script>
import { mixins } from "vue-chartjs";
import deferred from "chartjs-plugin-deferred";
// eslint-disable-next-line no-unused-vars
const { reactiveProp } = mixins;
import CustomRadial from "@/plugins/charts/RadialGaugeDoughnut";

export default {
  name: "ChartAchievementRate",
  extends: CustomRadial,
  props: {
    threshold: {
      type: Number,
      default: 0,
      required: true
    },
    value: {
      type: Number,
      default: 0,
      required: true
    }
  },
  computed: {
    _threshold() {
      return this.threshold > 100 ? 100 : this.threshold;
    },
    _value() {
      return this.value > 100 ? 100 : this.value;
    }
  },
  watch: {
    _threshold() {
      this._data._chart.update();
    },
    _value() {
      this._data._chart.destroy();
      this.renderTheChart();
    }
  },
  mounted() {
    this.addPlugin(deferred);
    this.renderTheChart();
  },
  methods: {
    renderTheChart() {
      this.renderChart(
        {
          label: [],
          datasets: [
            {
              label: "Data",
              backgroundColor: "#495BC4",
              borderWidth: 0,
              borderAlign: "inner",
              data: [this._value]
            },
            {
              label: "Threshold",
              backgroundColor: "#69E3FF",
              borderWidth: 0,
              data: [this._threshold]
            }
          ]
        },
        {
          trackColor: "#e5ebfa",
          centerPercentage: 100,
          responsive: true,
          maintainAspectRatio: true,
          devicePixelRatio: 1,
          tooltips: {
            intersect: false,
            enabled: false
          },
          elements: {
            arc: {}
          },
          centerArea: {
            fontFamily: "Roboto",
            fontSize: 70,
            fontColor: "#000000",
            backgroundColor: "#FFF"
          },
          plugins: {
            datalabels: {
              display: false
            },
            customAnnotationLabel: false,
            deferred: {
              xOffset: 150,
              yOffset: "50%",
              delay: 500
            }
          }
        }
      );
    }
  }
};
</script>
