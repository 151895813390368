<template>
  <v-row class="ml-2" justify="center">
    <v-col cols="5" class="pr-0">
      <v-row align="center" justify="center">
        <v-col cols="3" class="text-right">
          <v-icon :color="color">{{ icon }}</v-icon>
        </v-col>
        <v-col cols="9">
          <div class="text-heading-4 font-weight-medium" :class="titleColor">
            {{ title }}
          </div>
          <div class="main--text lighten-3 text-heading-1 font-weight-light">
            {{ subtitle }}
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="7" class="pl-0 text-left">
      <div class="font-weight-bold text-heading-8" :class="valueColor">
        {{ value | toCurrency }}
      </div>
      <div class="font-weight-regular main--text lighten-3 text-heading-1">
        {{ valueSubtitle }}
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ListItemSalesFigures",
  props: {
    icon: {
      type: String,
      default: "$flag",
      required: false
    },
    color: {
      type: String,
      default: "primary",
      required: false
    },
    title: {
      type: String,
      default: "",
      required: true
    },
    titleColor: {
      type: String,
      default: "main--text",
      required: false
    },
    subtitle: {
      type: String,
      default: "",
      required: false
    },
    value: {
      type: Number,
      default: 0,
      required: true
    },
    valueColor: {
      type: String,
      default: "main--text",
      required: false
    },
    valueSubtitle: {
      type: String,
      default: "",
      required: false
    }
  }
};
</script>

<style scoped></style>
