/* eslint-disable */
import Chart from "chart.js";
import { helpers } from "chart.js";
import radialGauge from "chartjs-chart-radial-gauge";

import { generateChart } from "vue-chartjs";

Chart.defaults.customRadial = Chart.defaults.radialGauge;
Chart.controllers.customRadial = Chart.controllers.radialGauge.extend({
  dataElementType: Chart.elements.RoundedArc,

  linkScales: helpers.noop,

  draw(...args) {
    if (this.chart.data.datasets.length - 1 === this.index) this.drawTrack();
    Chart.DatasetController.prototype.draw.call(this, args);
  },

  drawTrack() {
    new Chart.elements.Arc({
      _view: {
        backgroundColor: this.chart.options.trackColor,
        borderColor: this.chart.options.trackColor,
        startAngle: 0,
        endAngle: Math.PI * 2,
        x: this.centerX,
        y: this.centerY,
        innerRadius: this.innerRadius - (1 + this.index) * 5,
        outerRadius: this.outerRadius + (1 + this.index) * 5,
        borderWidth: this.borderWidth
      },
      _chart: this.chart
    }).draw();
  },

  update(reset) {
    const chart = this.chart;
    const chartArea = chart.chartArea;
    const opts = chart.options;
    const arcOpts = opts.elements.arc;
    const availableWidth =
      chartArea.right - chartArea.left - arcOpts.borderWidth;
    const availableHeight =
      chartArea.bottom - chartArea.top - arcOpts.borderWidth;
    const availableSize = Math.min(availableWidth, availableHeight);

    const meta = this.getMeta();
    const centerPercentage = opts.centerPercentage;

    this.borderWidth = this.getMaxBorderWidth(meta.data);
    this.outerRadius =
      Math.max((availableSize - this.borderWidth) / 2, 0) -
      5 * this.chart.data.datasets.length;
    this.innerRadius =
      Math.max(
        centerPercentage ? (this.outerRadius / 100) * centerPercentage : 0,
        0
      ) -
      5 * this.chart.data.datasets.length;

    meta.total = this.getMetricValue();
    this.centerX = (chartArea.left + chartArea.right) / 2;
    this.centerY = (chartArea.top + chartArea.bottom) / 2;

    helpers.each(meta.data, (arc, index) => {
      this.updateElement(arc, index, reset);
    });
  },

  updateElement(arc, index, reset) {
    const chart = this.chart;
    const chartArea = chart.chartArea;
    const opts = chart.options;
    const animationOpts = opts.animation;
    const centerX = (chartArea.left + chartArea.right) / 2;
    const centerY = (chartArea.top + chartArea.bottom) / 2;
    const startAngle = opts.rotation; // non reset case handled later
    const dataset = this.getDataset();
    const arcAngle =
      reset && animationOpts.animateRotate
        ? 0
        : this.calculateArcAngle(dataset.data[index]);
    const value =
      reset && animationOpts.animateScale ? 0 : this.getMetricValue();
    const endAngle = startAngle + arcAngle;
    const innerRadius = this.innerRadius - (1 + this.index) * 5;
    const outerRadius = this.outerRadius + (1 + this.index) * 5;
    const valueAtIndexOrDefault = helpers.valueAtIndexOrDefault;

    helpers.extend(arc, {
      // Utility
      _datasetIndex: this.index,
      _index: index,

      // Desired view properties
      _model: {
        x: centerX,
        y: centerY,
        startAngle,
        endAngle,
        outerRadius,
        innerRadius,
        label: valueAtIndexOrDefault(
          dataset.label,
          index,
          chart.data.labels[index]
        ),
        roundedCorners: opts.roundedCorners,
        value
      }
    });

    const model = arc._model;

    // Resets the visual styles
    const custom = arc.custom || {};
    const valueOrDefault = helpers.valueAtIndexOrDefault;
    const elementOpts = this.chart.options.elements.arc;
    model.backgroundColor = custom.backgroundColor
      ? custom.backgroundColor
      : valueOrDefault(
          dataset.backgroundColor,
          index,
          elementOpts.backgroundColor
        );
    model.borderColor = custom.borderColor
      ? custom.borderColor
      : valueOrDefault(dataset.borderColor, index, elementOpts.borderColor);
    model.borderWidth = custom.borderWidth
      ? custom.borderWidth
      : valueOrDefault(dataset.borderWidth, index, elementOpts.borderWidth);

    arc.pivot();
  },

  getMetricValue() {
    let value = this.getDataset().data[0];
    if (value == null) {
      value = this.chart.options.domain[0];
    }

    return value;
  },

  getDomain() {
    return this.chart.options.domain;
  },

  calculateArcAngle() {
    const [domainStart, domainEnd] = this.getDomain();
    const value = this.getMetricValue();
    const domainSize = domainEnd - domainStart;

    return domainSize > 0
      ? Math.PI * 2.0 * (Math.abs(value - domainStart) / domainSize)
      : 0;
  },

  // gets the max border or hover width to properly scale pie charts
  getMaxBorderWidth(arcs) {
    let max = 0;
    const index = this.index;
    const length = arcs.length;
    let borderWidth;
    let hoverWidth;

    for (let i = 0; i < length; i++) {
      borderWidth = arcs[i]._model ? arcs[i]._model.borderWidth : 0;
      hoverWidth = arcs[i]._chart
        ? arcs[i]._chart.config.data.datasets[index].hoverBorderWidth
        : 0;

      max = borderWidth > max ? borderWidth : max;
      max = hoverWidth > max ? hoverWidth : max;
    }
    return max;
  }
});

const CustomRadial = generateChart("custom-doughnut", "customRadial");

export default CustomRadial;
